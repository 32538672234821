@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

.switch-wrapper {
  position: relative;
}
.switch-wrapper > div {
  position: absolute;
}
.BrowserView {
  background-color: #de523c;
  min-height: 100vh;
  min-width: 100vw;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Poppins-Light';
  overflow: hidden;
}
.App {
  background-color: #de523c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-family: 'Poppins-Light';
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
  overflow: hidden;
}
.BenefitsApp {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins-Light';
  overflow: hidden;
}
.AdminApp {
  background-color: #fff;
  min-height: 100vh;
  min-width: 100vw;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-family: 'Poppins-Light';
}

.LoginContainer {
  background-color: #fff;
  min-height: calc(100vh - 0px);
  min-width: calc(100vw - 0px);
  border-radius: 0px 150px 0px 0px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
}
.CardContainer {
  background-color: #fff;
  min-height: calc(75vh - 0px);
  min-width: calc(100vw - 0px);
  margin-top: 25vh;
}
.HeaderBar {
  background-color: #de523c;
  height: 60px;
  min-width: calc(100vw - 0px);
  -webkit-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
}

.Card {
  background-color: #fff;
  height: calc(80vw * 0.65);
  min-width: 80vw;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);
  margin-top: calc(80vw * 0.65 / 2 * -1);
}
.CardDetails {
  height: calc(80vw * 0.65 - 70px);
  min-width: 80vw;
  align-items: flex-end;
}

.Note {
  text-align: left;
}

.Photo {
  float: left;
}
.LogoContainer {
  justify-content: flex-end;
}
.Logo {
  float: right;
  width: 100px;
  height: 44px;
  margin-top: 60px;
  margin-right: 60px;
}
.QR_Code {
  width: calc(80vw * 0.35);
  height: calc(80vw * 0.35);
  float: left;
}
.Exit {
  position: 'fixed';
  float: right;
  width: 30px;
  height: 30px;
  margin-top: 20px;
  margin-right: 15px;
}
.Back {
  position: 'fixed';
  float: left;
  width: 30px;
  height: 30px;
  margin-top: 15px;
  margin-left: 10px;
}
.BrandId {
  width: 120px;
  /*height: 80px;*/
}

ul {
	list-style: none;
}
.List {
  padding: 10px 30px 10px 0px;
}

.Header {
  font-size: 28px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  margin-left: 10vw;
  color: #de523c;
}

.FormContainer {
  padding: 0 10vw;
  justify-content: center;
}
.FormInput {
  margin-top: 15px;
}
.FormButton {
  margin-top: 30px;
  background-color: #de523c;
  border-radius: 50px;
  min-width: 80vw;
  height: 44px;
  color: #fff;
  font-size: 18px;
  line-height: 44px;
}
